/* MAIN THEME COLORS AND SHARED GLOBAL VARIABLES */
:root {
  --lightPrimary: #1d9345;
  --darkPrimary: #24292f;
  --darkSecondary: #011f48;
  --darkTheme: #212529;
  --lightTheme: #f8f9fa;
  --sideBarMaxWidth: 250px;
  --sideBarMinWidth: 70px;
  --sideBarHeight: 50px;
}

body {
  font-family: "Poppins", sans-serif !important;
}

h6 {
  font-size: 14px !important;
}

.global-disable-scrollbar::-webkit-scrollbar {
  background: transparent; /* Chrome/Safari/Webkit */
  width: 0px;
}

.global-no-margin-padding {
  margin: 0 !important;
  padding: 0 !important;
}

.global-animate-flash {
  animation: global-animation-flash 2s linear infinite;
}

@keyframes global-animation-flash {
  50% {
    opacity: 0;
  }
}

.global-flip-180 {
  transform: scaleY(-1);
}

.global-mirror {
  transform: scaleX(-1);
}

.global-sidebar-adjusted {
  margin-left: var(--sideBarMinWidth) !important;
}

@media (max-width: 800px) {
  .global-sidebar-adjusted {
    margin-left: 0 !important;
    margin-bottom: calc(var(--sideBarHeight) + 15px) !important;
  }
}
